<template>
  <div class="goog_name_cps">
    <div v-if="!this.$route.query.linktype&&this.$route.query.status==6" class="goog_name_bg">
      <div class="wphGoodsBox">
        <span class="sale_num">
          已售{{ dataList.sale_num }}件
        </span>
        折扣价 <i>¥ </i><span class="wphVipPrice">{{ dataList.wph_price }}</span><span
          class="wphOldPrice"
        >¥{{ dataList.original_price }}</span><span class="SharePrice">分享赚¥0.81</span>
      </div>
      <div class="wphGoodsTitle">
        {{ dataList.goods_name }}

      </div>

    </div>

    <div v-if="!this.$route.query.linktype&&this.$route.query.status!=6" class="goog_name_bg">
      <div class="goog_name">
        <div class="goog_name_title">收益{{ dataList.earnings }}元</div>
        <div class="goog_name_l">
          最高可获得收益{{ dataList.top_earnings }} 元
        </div>
      </div>
      <!--1-->
      <div class="price-now">
        <span class="special">券后价 ¥</span>
        <span>{{ dataList.current_price }}</span>
        <span class="price-old">¥{{ dataList.original_price }}</span>
        <span class="price-box">分享赚 {{ dataList.share_save_price }}</span>
        <span class="prices_ale_num">已售 {{ dataList.sale_num_string }}</span>
      </div>
      <!--2-->

      <div class="goods-title">
        <img class="types" :src="dataList.brand_logo">
        <span>{{ dataList.goods_name }}</span>
      </div>

      <div
        v-if="goodsInfo.coupon.discount && goodsInfo.coupon.discount != 0"
        class="cps-coupon"
      >
        <div class="price">
          <span class="spe">¥</span>
          <span>{{ goodsInfo.coupon.discount }}</span>
        </div>
        <div class="inf">
          <div class="spe">优惠券</div>
          <div>有效期到{{ goodsInfo.coupon.use_end_time }}</div>
        </div>
        <div class="get-coupon" @click="toOutside(goodsInfo.buy_url)">
          立即领取
        </div>
      </div>

      <!--3-->
      <div v-if="dataList.shop_name" class="goods_img">
        <img src="../../assets/images/img_sp.png" alt="">

        <span>{{ dataList.shop_name }}</span>
      </div>
    </div>

    <div v-if="!this.$route.query.linktype&&this.$route.query.status!=6" class="share_data">
      <div class="share_data_ov">
        <div class="share_data_l">
          <span> 规则：</span>
          <span class="share_data_style">返利说明</span>
        </div>
        <div class="share_data_r">
          <img
            src="../../assets/images/MoreIocn.png"
            alt=""
            @click="growCount()"
          >
        </div>
      </div>
      <div class="share_ov">
        <div class="share_l">分享文案:</div>
        <div id="share_r" class="share_r">{{ dataList.share_data }}</div>
      </div>
      <div class="share_data_btn" @click="copy()">复制文案</div>
    </div>

    <div v-if="this.$route.query.linktype" class="linktypeBox">
      <div class="linktypeBg">
        <div class="linktypePriceBox">
          <div class="linktypePrice"><i>¥</i> {{ goodsInfo.goodVipPrice }}</div>
          <div class="linktypeOldPrice">
            <span class="linktypeOld"> ¥{{ goodsInfo.goodPrice }} </span>
            <span class="linktypeTitle"> 折后价格 </span>
          </div>
          <div class="linktypeNum">已售：{{ million(goodsInfo.monthSales) }}</div>
        </div>

        <h3 class="linktypeGoodName">
          {{ goodsInfo.goodName }}
        </h3>

        <div
          v-if="goodsInfo.coupon.discount && goodsInfo.coupon.discount != 0"
          class="linktypeCoupon"
        >
          <div
            v-if="goodsInfo.coupon.discount && goodsInfo.coupon.discount != 0"
            class="cps-coupon"
          >
            <div class="price">
              <span class="spe">¥</span>
              <span>{{ goodsInfo.coupon.discount }}</span>
            </div>
            <div class="inf">
              <div class="spe">优惠券</div>
              <div>有效期到{{ goodsInfo.coupon.use_end_time }}</div>
            </div>
            <div class="get-coupon" @click="toOutside(goodsInfo.buy_url)">
              立即领取
            </div>
          </div>
        </div>
      </div>

      <div class="recommendBox">
        <div class="recommendTitle">
          <img src="../../assets/images/recommendIcon.png">
          <span class="recommendName"> 为你推荐 </span>
        </div>
        <div class="recommendListBox">
          <skeleton v-if="recommendList.length === 0" type="list" />
          <Waterfalls
            v-else
            id="dataList"
            :col="2"
            :list="recommendList"
            :index="tabIndexs"
          >
            <template slot-scope="{ data }">
              <!-- {{data}} -->
              <div class="recommended_item" @click="listLink(data)">
                <div class="recommended_item_img">
                  <imgLoading :src="data.mainPic" />
                </div>
                <div class="recommended_item_box">
                  <div class="tit">
                    <!-- <img :src="data.channelLogo" alt="" /> -->
                    {{ data.title }}
                  </div>

                  <div class="desc">{{ data.couponPrice }}元券</div>
                  <!-- <div class="desc">
                    <span class="desc_jd" v-if="data.couponAmountName != ' '">{{
                      data.couponAmountName != " "
                        ? data.couponAmountName + "元券"
                        : ""
                    }}</span>
                    <span class="desc_one"
                      >分享赚¥{{ data.growthValue.share }}</span
                    >
                  </div> -->
                  <div class="money">
                    <em>¥</em>{{ data.actualPrice }}<span>折后</span>
                    <!-- <span> -->
                    <!-- <em>¥</em> -->
                    <!-- <span>{{ data.goodPrice }}</span> -->
                    <!-- </span> -->
                  </div>
                </div>
              </div>
            </template>
          </Waterfalls>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import Waterfalls from '@/component/Waterfalls/index'
import skeleton from '@/component/skeleton/index'
import imgLoading from '@/component/imgLoading'
import { querySimilerGoodsByOpen, linkTransfer } from '@/services/goods.js'
import { copyText } from '@/utils/common/utils'

export default {
  name: 'GoogNamecps',
  components: {
    Waterfalls,
    skeleton,
    imgLoading
  },
  props: {
    goodsInfo: {
      // 商品详情数据
      type: Object,
      default: () => {
        return {}
      }
    },
    dataList: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      recommendList: [],
      tabIndexs: 0
    }
  },
  mounted() {
    if (this.$route.query.linktype) {
      this.getRecommendList()
    }
  },
  methods: {
    // 处理万件格式
    million(value) {
      if (value * 1 && (value * 1) > 9999) {
        return (value / 10000) + '万件'
      } else {
        return value + '件'
      }
    },
    listLink(item) {
      // goodsId
      const obj = {
        product_type: 5,
        id: item.id,
        goodId: item.goodsId,
        skuId: '',
        linktype: 55
      }
      this.$store.dispatch('godetail', obj)
      this.$router.go(0)
    },
    // 获取推荐列表
    getRecommendList() {
      const body = {
        id: this.$route.query.id
      }

      querySimilerGoodsByOpen(body).then((res) => {
        if (Number(res.code) === 200) {
          this.recommendList = res.data
        }
      })
    },

    toOutside(url) {
      if (window.localStorage.getItem('uid')) {
        if (this.$route.query.linktype) {
          const data = {
            mid: window.localStorage.getItem('uid'),
            goodsId: this.$route.query.goodsId
          }

          linkTransfer(data).then((res) => {
            if (Number(res.code) === 200) {
              window.location.href = res.data.couponClickUrl
            }
          })
        } else {
          window.location.href = url
          // 点击去购买
        }
      } else {
        this.$store.dispatch('loginIntercept', this.$route.query)
      }
    },
    // 复制文案
    copy() {
      copyText(this.dataList.share_data, () => {
        Toast('复制成功')
      })
    },
    growCount: function() {
      this.$router.push('/growCount')
    }
  }
}
</script>
<style scoped lang="less">
.goog_name_cps {
  .goog_name_bg {
    background: #fff;

    .goog_name {
      overflow: hidden;
      width: 100%;
      height: 40px;
      background: url("../../assets/images/tabbg.png") no-repeat;
      background-size: 100% 100%;
      position: relative;

      .goog_name_l {
        line-height: 40px;
        text-align: left;
        float: left;
        color: #e33f44;
        margin-left: 12px;
        font-weight: 500;
      }

      .goog_name_title {
        height: 40px;
        padding: 0 12px;
        background: #e33f44;
        border-radius: 0 20px 20px 0;
        line-height: 40px;
        font-size: 16px;
        color: #fff;
        float: left;
      }
    }

    .goods-title {
      overflow: hidden;
      margin: 12px;
      font-size: 14px;
      color: #141414;
      text-align: justify;

      .types {
        width: 14px;
        float: left;
        margin-right: 3px;
      }
    }

    .price-now {
      overflow: hidden;
      text-align: left;
      margin: 12px;
      color: #ff001c;
      font-size: 21px;

      .special {
        font-size: 14px;
      }

      .price-old {
        color: #999999;
        font-size: 12px;
        text-decoration: line-through;
        margin-left: 12px;
      }

      .price-box {
        font-size: 12px;
        border: 1px solid #e33f44;
        background: rgba(191, 55, 55, 0.3);
        border-radius: 4px;
        padding: 2px 5px;
        margin-left: 12px;
        vertical-align: 2px;
      }

      .prices_ale_num {
        color: #999999;
        font-size: 12px;
        float: right;
        line-height: 32px;
      }
    }

    .goods_img {
      margin: 16px 12px 0px 12px;
      text-align: justify;
      line-height: 24px;
      font-weight: 500;
      padding-bottom: 8px;
      box-sizing: border-box;

      img {
        width: 20px;
        vertical-align: -6px;
        margin-right: 5px;
        color: #999;
        font-weight: 500;
      }
    }

    .cps-coupon {
      margin: 12px;
      height: 78px;
      background: url("../../assets/images/bj_imgs.png") no-repeat;
      background-size: 100% 100%;
      position: relative;

      .price {
        font-size: 26px;
        color: #ed2e2e;
        position: absolute;
        left: 40px;
        top: 50%;
        transform: translateY(-50%);
        font-weight: bold;

        .spe {
          font-size: 16px;
        }
      }

      .inf {
        color: #ed2e2e;
        font-size: 12px;
        position: absolute;
        left: 130px;
        top: 50%;
        transform: translateY(-50%);

        .spe {
          font-size: 15px;
          margin-bottom: 4px;
          text-align: left;
        }
      }

      .get-coupon {
        width: 80px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #feffed;
        font-size: 14px;
        position: absolute;
        right: 22px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 15px;
        background: linear-gradient(180deg,
        rgba(247, 91, 91, 1) 0%,
        rgba(237, 46, 46, 1) 100%);
      }
    }
  }

  .share_data {
    background: #fff;
    margin-top: 10px;
    width: 100%;
    padding-bottom: 1px;
    box-sizing: border-box;

    .share_data_ov {
      padding: 12px;
      box-sizing: border-box;
      overflow: hidden;

      .share_data_l {
        float: left;

        span {
          color: #666;
        }

        .share_data_style {
          color: #333;
        }
      }

      .share_data_r {
        float: right;

        img {
          width: 7px;
          height: auto;
        }
      }
    }

    .share_ov {
      width: 100%;
      padding: 0 12px;
      box-sizing: border-box;
      overflow: hidden;
      text-align: left;

      .share_l {
        width: 65px;
        float: left;
        color: #666;
      }

      .share_r {
        float: left;
        width: 285px;
        white-space: pre-wrap;
        word-wrap: break-word;
      }
    }

    .share_data_btn {
      width: 190px;
      margin: 36px auto 12px;
      background: #e33f44;
      height: 26px;
      line-height: 26px;
      color: #fff;
      font-size: 12px;
      border-radius: 12px;
      font-weight: 500;
    }
  }
}

.linktypeBox {
  text-align: left;

  .linktypeBg {
    background: #fff;
    padding: 10px;

    .linktypePriceBox {
      overflow: hidden;

      .linktypePrice {
        float: left;
        color: #f7263c;
        font-size: 24px;
        margin-right: 10px;

        i {
          font-size: 12px;
          font-style: normal;
        }
      }

      .linktypeOldPrice {
        float: left;
        font-size: 12px;
        margin-right: 10px;

        span {
          display: block;
        }

        .linktypeOld {
          color: #999999;
          text-decoration: line-through;
        }

        .linktypeTitle {
          font-weight: 400;

          color: #f7263c;
        }
      }

      .linktypeNum {
        float: left;
        line-height: 38px;
        font-size: 12px;
        color: #999999;
      }
    }

    .linktypeGoodName {
      font-size: 16px;
      line-height: 22px;
      font-weight: bold;
    }

    .linktypeCoupon {
      .cps-coupon {
        margin: 12px 0;
        height: 78px;
        background: url("../../assets/images/bj_imgs.png") no-repeat;
        background-size: 100% 100%;
        position: relative;

        .price {
          font-size: 26px;
          color: #ed2e2e;
          position: absolute;
          left: 40px;
          top: 50%;
          transform: translateY(-50%);
          font-weight: bold;

          .spe {
            font-size: 16px;
          }
        }

        .inf {
          color: #ed2e2e;
          font-size: 12px;
          position: absolute;
          left: 130px;
          top: 50%;
          transform: translateY(-50%);

          .spe {
            font-size: 15px;
            margin-bottom: 4px;
            text-align: left;
          }
        }

        .get-coupon {
          width: 80px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          color: #feffed;
          font-size: 14px;
          position: absolute;
          right: 22px;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 15px;
          background: linear-gradient(180deg,
          rgba(247, 91, 91, 1) 0%,
          rgba(237, 46, 46, 1) 100%);
        }
      }
    }
  }
}

.recommendBox {
  padding: 10px;

  .recommendTitle {
    height: 19px;

    .recommendName {
      font-size: 16px;
      color: #000;
      font-weight: bold;
      margin-right: 10px;
    }

    // padding: 10px 0;
    img {
      height: 19px;
      width: auto;
      vertical-align: middle;
    }
  }
}

.recommendListBox {
  width: 100%;

  .recommended_item {
    .recommended_item_img {
      // height: 170px;
    }

    .recommended_item_box {
      margin: 0 8px;
    }

    width: 100%;
    background: #fff;
    border-radius: 9px;
    margin-bottom: 9px;

    overflow: hidden;

    img {
      height: 100px;
    }

    .tit {
      color: #191919;
      font-size: 14px;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2; /*要显示的行数*/
      -webkit-box-orient: vertical;
      margin: 10px 0 5px 0;
      line-height: 20px;
      font-weight: bold;

      img {
        width: 16px;
        height: 14px;
        border-radius: 4px;
      }
    }

    .desc {
      width: 68px;
      height: 24px;
      background: url("../../assets/images/yhj_red.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 24px;
      color: #fff;

      // .desc_one {
      //   color: red;
      //   font-size: 10px;
      //   background: rgba(212, 146, 146, 0.4);
      //   display: initial;
      //   border: 1px solid red;
      //   border-radius: 4px;
      //   padding: 0 4px;
      //   box-sizing: border-box;
      // }
      // .desc_jd {
      //   width: 68px;
      //   height: 14px;
      //   background: url("../../assets/images/yhj_red.png") no-repeat;
      //   background-size: 100% 100%;
      //   font-size: 10px;
      //   color: #fff;
      //   padding: 1px 3px;
      //   box-sizing: border-box;
      //   margin-right: 12px;
      // }
    }

    .money {
      font-size: 17px;
      color: #c82519;
      //font-weight: bold;
      margin-top: 10px;
      margin-bottom: 4px;
      font-weight: bold;

      span {
        color: #c82519;
        font-size: 12px;
        margin-left: 2px;
        line-height: 23px;
      }

      em {
        font-size: 12px;
        font-style: normal;
      }
    }
  }
}

.wphGoodsBox {
  position: relative;
  text-align: left;
  padding: 10px;
  // margin-top:10px;
  color: red;
  font-size: 12px;

  .sale_num {
    background: #000;
    color: #fff;
    position: absolute;
    top: -20px;
    right: 0;
    padding: 3px 6px;
    border-radius: 3px;
    font-size: 12px;

  }

  span {
    margin-right: 5px;
  }

  i {
    font-style: normal;

  }

  .wphVipPrice {
    font-size: 18px;
  }

  .wphOldPrice {
    font-size: 12px;
    text-decoration: line-through;
    color: #666;

  }

  .SharePrice {
    text-align: center;
    padding: 3px 5px;
    color: red;
    border: 1px solid red;
    background: #ffc5c5;
    border-radius: 5px;

  }
}

.wphGoodsTitle {
  text-align: left;
  padding: 10px;
  font-weight: bold;
  line-height: 20px;

}
</style>
