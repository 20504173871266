<template>
  <div class="goods-detail">

    <skeleton v-if="!goodsInfo" type="detail" />
    <div v-else>

      <!-- 顶部和底部 -->
      <openApp :showtig="true" :is-show-open-app="isShowOpenApp" :goods-status="goodsStatus" :goods-info="goodsInfo" :flash-info="flashInfo" />
      <!-- banner图片 -->
      <van-swipe :autoplay="3000" indicator-color="#ED2E2E">
        <van-swipe-item v-for="(image, index) in goodsInfo.banner" :key="index">
          <img v-lazy="image" class="banner">
        </van-swipe-item>
      </van-swipe>
      <!-- 商品价格，商品名称，商品描述  -->
      <googNamecps :flash-info="flashInfo" :goods-info="goodsInfo" :data-list="dataList" />
      <!-- 商品详情 -->
      <div v-if="goodsInfo.goodContent" class="goods-detail-info">
        <div class="tit">商品详情</div>
        <div v-if="goodsStatus != 6 && goodsStatus != 7" style="width: 100%" v-html="goodsInfo.goodContent" />
        <div v-else style="width: 100%">
          <img v-for="(item, index) in goodsInfo.goodContent" :key="index" style="width: 100%" :src="item">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* eslint-disable eqeqeq */
import Vue from 'vue'
import { Lazyload, Swipe, SwipeItem } from 'vant'

Vue.use(Swipe).use(SwipeItem).use(Lazyload)
import skeleton from '@/component/skeleton/index'
import {
  getWPHGoodsDetail, getSNGoodsDetail, getCpsGoodsDetail, getQueryGoodsDetail
} from '@/services/goods.js'
import OpenApp from '@/component/GoodsDetail/goodsTopBtm'
import googNamecps from '@/component/GoodsDetail/googNamecps'

// import goodsInfo from '@/component/GoodsDetail/goodsInfo'
import { getJDGoodsDetail } from '@/services/goods'
import { pddAuth } from '@/utils/pdd'
import { fetchJDGoodsDetails } from '@/services/shop-cps/shop-jd-cps'
import { fetchPddGoodsDetails } from '@/services/shop-cps/shop-pdd-cps'
import { fetchTBDetail } from '@/services/shop-cps/shop-tb-cps'
import { isYTApp } from '@/utils/common/validate'
// import { taoBaoAuth } from '@/utils/tao-bao'

export default {
  name: 'GoodsDetailCps',
  components: {
    OpenApp,
    googNamecps,
    skeleton
  },
  data() {
    return {
      flashInfo: {
        state: ''
      },
      goodsStatus: 3, // 1:自营详情；2:秒杀详情；3:京东详情；4:拼多多详情；5:淘宝详情；6:唯品会详情
      goodsId: '', // 产品ID
      id: '',
      codeNumber: '', // 分享码
      room_id: '', // 关系id
      live_id: '', // 直播间id
      live_people_id: '', // 主播id
      uid: 0, // 用户ID
      goodsInfo: null, // 商品信息
      isShowOpenApp: !isYTApp(), // 是否展示打开App的商品信息
      supplier_code: '', // 苏宁渠道
      isReady: false, // 详情接口是否请求完成
      dataList: '',
      isVideoNumBuy: null,
      PromoterUid: null, // 推广人Id
      eliteType: null // 京东礼金类型id
    }
  },
  mounted() {
    const { PromoterUid = null, isVideoNumBuy = null, status = 3, eliteType } = this.$route.query
    this.PromoterUid = PromoterUid // 推广人Id
    this.eliteType = eliteType // 京东礼金类型id
    this.isVideoNumBuy = isVideoNumBuy // 是否视频号订单
    this.$store.commit('changeInterceptUrl', window.location.href)
    // this.$store.dispatch('loginIntercept',this.$route.query)
    const _sgoodsstatus = Number(status) // 1:自营详情；2:秒杀详情；3:京东详情；4:拼多多详情；5:淘宝详情；6:唯品会详情；7:苏宁详情
    this.goodsStatus = _sgoodsstatus
    this.goodsId = this.$route.query.goodsId || this.$route.query.id || '' // 产品ID
    if (this.$route.query.id) {
      this.id = this.$route.query.id
    }
    this.codeNumber = this.$store.state.codeNumber || '' // codeID
    this.room_id = this.$route.query.room_id || this.$store.state.room_id || '' // 关系ID
    this.robot_send =
      this.$route.query.robot_send || this.$store.state.robot_send || '' // 机器人
    this.live_id = this.$route.query.live_id || this.$store.state.live_id || '' // 直播间ID
    this.live_people_id =
      this.$route.query.live_people_id ||
      this.$route.query.live_mid ||
      this.$store.state.live_people_id ||
      '' // 主播ID
    this.supplier_code = this.$route.query.supplier_code || '' // 苏宁渠道
    this.uid = window.localStorage.getItem('uid') || 0 // 用户ID
    switch (_sgoodsstatus) {
      case 3:
        this.getCpsGoodsDetail({ channel_id: 100, brand: 'jd' })
        break
      case 4:
        this.getCpsGoodsDetail({ channel_id: 98, brand: 'pdd' })
        // this.getPDDGoodsDetail();
        break
      case 5:
        if (this.$route.query.linktype && this.$route.query.linktype === 55) {
          this.getQueryGoodsDetail({ channel_id: 99, brand: 'tb' })
        } else {
          this.getCpsGoodsDetail({ channel_id: 99, brand: 'tb' })
        }

        // this.getTBGoodsDetail();
        break
      case 6:
        this.getWPHGoodsDetail()
        break
      case 7:
        this.getSNGoodsDetail()
        break
      default:
        this.getJDGoodsDetail()
    }
  },
  methods: {
    // 京东、淘宝、拼多多商品详情
    async getCpsGoodsDetail(item) {
      if (item.brand === 'pdd') {
        await pddAuth()
      }
      // if (item.brand === 'tb') {
      //   await taoBaoAuth()
      // }
      const data = {
        goods_id: this.goodsId,
        // codeNumber:this.codeNumber,
        room_id: this.room_id || '',
        robot_send: this.robot_send || '',
        // liveId: this.live_id,
        // live_people_id:this.live_people_id,
        liveId: this.PromoterUid || this.live_id,
        liveMid: this.live_people_id,
        channel_id: this.isVideoNumBuy === '1' ? 14 : item.channel_id,
        brand: item.brand,
        type: 2, // 1 app 2 h5 3 小程序
        uid: this.uid // 用户ID
      }

      let c = getCpsGoodsDetail

      if (item.brand === 'jd') {
        if (this.eliteType) {
          data.eliteType = this.eliteType
        }
        c = fetchJDGoodsDetails
      }
      if (item.brand === 'pdd') {
        c = fetchPddGoodsDetails
      }
      if (item.brand === 'tb') {
        c = fetchTBDetail
      }

      c(data).then((res) => {
        if (res.code == 200) {
          this.dataList = res.data
          const obj = {
            banner: res.data.goods_image || [],
            goodName: res.data.goods_name || '',
            goodsInfo: res.data.goods_desc || '',
            channelIcon:
              'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-02-23/09/yuelvhuiI9ee3xGzmq1582420967.png',
            goodPrice: res.data.original_price,
            goodVipPrice: res.data.current_price,
            goodContent: res.data.pict_url,
            // coupon: res.data.coupon,
            buy_url: res.data.jump_url,
            showPrice: res.data.current_price,
            showRemind: '券后价',
            share_save_price: res.data.share_save_price,
            // top_earnings: res.data.top_earnings,
            sale_num: res.data.sale_num,
            top_earnings: res.data.top_earnings,
            coupon: {
              discount: res.data.coupon_amount,
              use_end_time: res.data.coupon_end_time
            }
          }
          this.goodsInfo = obj
          this.isReady = true
        } else if (res.code == 400) {
          if (item.channel_id == 99) {
            if (!window.localStorage.getItem('uid')) {
              this.$store.dispatch('loginIntercept', this.$route.query)
            } else {
              this.$router.go(-1)
            }
          } else {
            this.$router.go(-1)
          }
        }
      })
    },
    // 排行榜详情

    getQueryGoodsDetail(item) {
      const data = {
        id: this.id,
        mid: this.uid // 用户ID
      }

      getQueryGoodsDetail(data).then(res => {
        if (res.code == 200) {
          this.dataList = res.data
          const obj = {
            banner: res.data.imgs || [],
            goodName: res.data.title || '',
            goodsInfo: res.data.goods_desc || '',

            channelIcon:
              'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-02-23/09/yuelvhuiI9ee3xGzmq1582420967.png',
            goodPrice: res.data.originalPrice,
            goodVipPrice: res.data.actualPrice,
            goodContent: res.data.pict_url,
            // coupon: res.data.coupon,
            buy_url: res.data.jump_url,
            showPrice: res.data.current_price,
            showRemind: '折后价格',
            monthSales: res.data.monthSales,
            share_save_price: res.data.share_save_price,
            // top_earnings: res.data.top_earnings,
            sale_num: res.data.sale_num,
            top_earnings: res.data.top_earnings,
            coupon: {
              discount: res.data.couponPrice,
              use_end_time: res.data.couponEndTime
            }
          }
          this.goodsInfo = obj
          this.isReady = true
        }
      })
    },

    getWPHGoodsDetail() {
      // 获取唯品会详情
      const data = {
        goods_id: this.goodsId,
        codeNumber: this.codeNumber,
        room_id: this.room_id,
        robot_send: this.robot_send,
        liveMid: this.live_people_id,
        liveId: this.live_id,
        live_id: this.live_id,
        live_people_id: this.live_people_id,
        uid: this.uid
      }
      getWPHGoodsDetail(data).then((res) => {
        if (res.code == 200) {
          res.data.shop_name = res.data.goods_name
          this.dataList = res.data
          // let obj = {
          //   banner: res.data && (res.data.goods_image || []),
          //   goodName: res.data && (res.data.goods_name || ""),
          //   goodsInfo: res.data && (res.data.goods_desc || ""),
          //   goodPrice: res.data && (res.data.original_price || 0),
          //   goodVipPrice: res.data && (res.data.wph_price || 0),
          //   goodContent: res.data && (res.data.detailed || []),
          //   buy_url: res.data && (res.data.buy_url || ""),
          //   showPrice: res.data && (res.data.wph_price || 0),
          //   showRemind: "券后价",
          // };

          const obj = {
            banner: res.data.goods_image || [],
            goodName: res.data.goods_name || '',

            goodsInfo: res.data.goods_desc || '',

            // channelIcon:"https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-02-23/09/yuelvhuiI9ee3xGzmq1582420967.png",
            goodPrice: res.data.original_price,
            goodVipPrice: res.data.wph_price,
            goodContent: res.data.detailed,
            buy_url: res.data.buy_url,
            showPrice: res.data.wph_price,
            showRemind: '券后价',
            newShareScore: res.data.newShareScore
            // monthSales: res.data.monthSales,
            // share_save_price: res.data.share_save_price,
            // // top_earnings: res.data.top_earnings,
            // sale_num: res.data.sale_num,
            // top_earnings: res.data.top_earnings,
            // coupon: {
            //   discount: res.data.couponPrice,
            //   use_end_time: res.data.couponEndTime,
            // },
          }

          this.goodsInfo = obj
          this.isReady = true
        } else {
          this.$router.go(-1)
        }
      })
    },
    getSNGoodsDetail() {
      // 获取苏宁详情
      const data = {
        goods_id: this.goodsId,
        codeNumber: this.codeNumber,
        room_id: this.room_id,
        robot_send: this.robot_send,
        liveId: this.live_id,
        live_id: this.live_id,
        liveMid: this.live_people_id,
        live_people_id: this.live_people_id,
        supplier_code: this.supplier_code,
        uid: this.uid,
        version: 'v2'
      }
      getSNGoodsDetail(data).then((res) => {
        if (res.code == 200) {
          const obj = {
            banner: res.data && (res.data.goods_image || []),
            goodName: res.data && (res.data.goods_name || ''),
            goodsInfo: res.data && (res.data.goods_desc || ''),
            goodPrice: res.data && (res.data.original_price || 0),
            goodVipPrice: res.data && (res.data.price || 0),
            goodContent: res.data && (res.data.detailed || []),
            buy_url: res.data && (res.data.jump_sn || ''),
            showPrice: res.data && (res.data.price || 0),
            showRemind: '券后价'
          }
          this.goodsInfo = obj
          this.isReady = true
        } else {
          this.$router.go(-1)
        }
      })
    },
    /**
     * 获取京东商品信息
     * 2021-05-17 10:45:59
     * @author SnowRock
     */
    getJDGoodsDetail(item) {
      const params = {
        goods_id: this.goodsId,
        room_id: this.room_id || '',
        robot_send: this.robot_send || '',
        liveId: this.PromoterUid || this.live_id,
        liveMid: this.live_people_id,
        channel_id: this.isVideoNumBuy === '1' ? 14 : item.channel_id,
        brand: item.brand,
        type: 2, // 1 app 2 h5 3 小程序
        uid: this.uid // 用户ID
      }
      getJDGoodsDetail(params).then(res => {
        const { code } = res
        if (Number(code) === 200) {
          this.dataList = res.data
          const obj = {
            banner: res.data.goods_image || [],
            goodName: res.data.goods_name || '',
            goodsInfo: res.data.goods_desc || '',
            channelIcon:
                'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-02-23/09/yuelvhuiI9ee3xGzmq1582420967.png',
            goodPrice: res.data.original_price,
            goodVipPrice: res.data.current_price,
            goodContent: res.data.pict_url,
            // coupon: res.data.coupon,
            buy_url: res.data.jump_url,
            showPrice: res.data.current_price,
            showRemind: '券后价',
            share_save_price: res.data.share_save_price,
            // top_earnings: res.data.top_earnings,
            sale_num: res.data.sale_num,
            top_earnings: res.data.top_earnings,
            coupon: {
              discount: res.data.coupon_amount,
              use_end_time: res.data.coupon_end_time
            }
          }
          this.goodsInfo = obj
          this.isReady = true
        } else if (res.code == 400) {
          if (item.channel_id == 99) {
            if (!window.localStorage.getItem('uid')) {
              this.$store.dispatch('loginIntercept', this.$route.query)
            } else {
              this.$router.go(-1)
            }
          } else {
            this.$router.go(-1)
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.goods-detail {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 75px;
  overflow-x: hidden;
  background: #f5f7fa;
  .banner {
    width: 100%;
    height: 357px;
    background: #999;
  }
  .line {
    width: 100%;
    height: 7px;
    background: #f8f8f8;
  }
  .goods-detail-info {
    width: 100%;
    margin-top: 12px;
    background: #fff;
    .tit {
      width: 100%;
      height: 38px;
      line-height: 38px;
      text-align: center;
      font-size: 15px;
      color: #141414;
    }
    ::v-deep img {
      display: block;
      width: 100%;
    }
  }
}
</style>
