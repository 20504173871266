import request from '@/services/request'
import { targetUrl } from '@/services/shop-cps/shop-jd-cps'

/**
 * 获取淘宝是否授权
 * 2021-06-02 15:18:40
 * @author SnowRock
 * @param params 入参
 * @param params.uid 用户id
 * @returns {Promise<*|*|undefined>}
 */
export function fetchTaoBaoAuth(params) {
  return request({
    url: targetUrl('/cpsreforge/tb/tbCpsIsAuthorized'),
    method: 'post',
    data: params
  })
}

/**
 * 获取淘宝详情
 * 2021-06-06 20:12:34
 * @author SnowRock
 * @param params
 * @returns {Promise<*|undefined>}
 */
export function fetchTBDetail(params) {
  return request({
    url: targetUrl('/cpsreforge/tb/tbCpsGetProductInfo'),
    method: 'post',
    data: params
  })
}
